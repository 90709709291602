import "./styles.css";
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import Form from "../../components/Form";
import CtaWpp from "../../components/CTAWpp";

import header3_1 from "../../assets/header3_1.png";
import header3_2 from "../../assets/header3_2.png";
import header3_3 from "../../assets/header3_3.png";
import header3_4 from "../../assets/header3_4.png";
import header3_5 from "../../assets/header3_5.png";
import insta from "../../assets/insta.png";

SwiperCore.use([Navigation, Pagination, Autoplay]);

const data = [
  {
    id: 1,
    image: header3_1,
  },
  {
    id: 2,
    image: header3_2,
  },
  {
    id: 3,
    image: header3_3,
  },
  {
    id: 4,
    image: header3_4,
  },
  {
    id: 5,
    image: header3_5,
  },
];

const Header = () => {
  return (
    <header id="header">
      <section className="header1">
        <article className="container">
          <div className="left vertical">
            <h2 className="title">
              Um resort pro seu pet em São Paulo. Liberdade e diversão! Veja o
              vídeo!
            </h2>

            {/* <p className="text">
              No <b> Clube de Cãopo</b> os filhotes terão espaço, pista de
              corrida, piscina, muito verde e muita grama.
            </p>

            <CtaWpp text={"Solicitar um orçamento"} /> */}

            <iframe
              src="https://www.youtube.com/embed/xEZTZvK3eqk"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              className="video"
            ></iframe>

            <a
              href="https://www.instagram.com/clubedecaoposp"
              target="_blank"
              rel="noreferrer"
              className="insta"
            >
              <figure>
                <img src={insta} alt="CTA pro Instagram" />
              </figure>
            </a>
          </div>

          <Form />
        </article>
      </section>

      <section className="header2 bg_verde">
        <article className="container">
          <h2 className="title">
            Pra nós, <span className="yellow"> seu pet é como um filho</span>,
            por isso segurança e cuidado são palavras de ordem por aqui!
          </h2>

          <div className="mobile">
            <Form />
          </div>
        </article>
      </section>

      <section className="header3">
        <article className="container">
          <p className="text yellow">Nossos Diferenciais</p>

          <h2 className="title">
            Aqui, seu doguinho terá acesso a diversos cuidados, lazer e
            diversão!
          </h2>

          <Swiper
            className="carousel"
            spaceBetween={20}
            slidesPerView={
              /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                navigator.userAgent
              )
                ? 1
                : 4
            }
            navigation
            loop
            pagination={{ clickable: true }}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
          >
            {data.map(({ id, image }) => {
              return (
                <SwiperSlide key={id} className="img">
                  <figure>
                    <img src={image} alt={`Exemplo ${id} de Foto`} />
                  </figure>
                </SwiperSlide>
              );
            })}
          </Swiper>

          <CtaWpp
            text={
              /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                navigator.userAgent
              )
                ? "Solicitar um orçamento"
                : "Solicitar um orçamento via WhatsApp"
            }
          />
        </article>
      </section>
    </header>
  );
};

export default Header;
