import "./styles.css";
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import CtaWpp from "../../components/CTAWpp";

import about1_mobile from "../../assets/mobile/about1_mobile.png";
import about1_1 from "../../assets/about1_1.png";
import about1_2 from "../../assets/about1_2.png";
import about1_3 from "../../assets/about1_3.png";
import about1_4 from "../../assets/about1_4.png";
import about2_stars from "../../assets/about2_stars.png";
import about2_left_1 from "../../assets/about2_left_1.png";
import about2_left_2 from "../../assets/about2_left_2.png";
import about2_left_3 from "../../assets/about2_left_3.png";
import about2_1 from "../../assets/about2_1.png";
import about2_2 from "../../assets/about2_2.png";
import about2_3 from "../../assets/about2_3.png";
import about2_4 from "../../assets/about2_4.png";
import about3_1 from "../../assets/about3_1.png";
import about3_2 from "../../assets/about3_2.png";
import about3_3 from "../../assets/about3_3.png";
import about3_4 from "../../assets/about3_4.png";
import about3_5 from "../../assets/about3_5.png";
import about4_1 from "../../assets/about4_1.png";
import about4_2 from "../../assets/about4_2.png";
import about4_3 from "../../assets/about4_3.png";

SwiperCore.use([Navigation, Pagination, Autoplay]);

const data = [
  {
    id: 1,
    image: about1_1,
  },
  {
    id: 2,
    image: about1_2,
  },
  {
    id: 3,
    image: about1_3,
  },
  {
    id: 4,
    image: about1_4,
  },
];

const data2 = [
  {
    id: 1,
    image: about2_1,
  },
  {
    id: 2,
    image: about2_2,
  },
  {
    id: 3,
    image: about2_3,
  },
  {
    id: 4,
    image: about2_4,
  },
];

const data3 = [
  {
    id: 1,
    image: about3_1,
  },
  {
    id: 2,
    image: about3_2,
  },
  {
    id: 3,
    image: about3_3,
  },
  {
    id: 4,
    image: about3_4,
  },
  {
    id: 5,
    image: about3_5,
  },
];

const About = () => {
  return (
    <section id="about">
      <article className="about1">
        <div className="container">
          <p className="text orange">Nossos Serviços</p>

          <h2 className="title gray">
            É sempre um prazer poder cuidar do bem-estar do seu amiguinho!
          </h2>

          {/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
            navigator.userAgent
          ) ? (
            <figure className="mobile">
              <img src={about1_mobile} alt="Mobile" />
            </figure>
          ) : (
            <Swiper
              className="carousel"
              spaceBetween={20}
              slidesPerView={
                /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                  navigator.userAgent
                )
                  ? 1
                  : 4
              }
              navigation
              loop
              pagination={{ clickable: true }}
              autoplay={{
                delay: 2000,
                disableOnInteraction: false,
              }}
            >
              {data.map(({ id, image }) => {
                return (
                  <SwiperSlide key={id} className="img">
                    <figure>
                      <img src={image} alt={`Exemplo ${id} de Foto`} />
                    </figure>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          )}

          <CtaWpp
            text={
              /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                navigator.userAgent
              )
                ? "Solicitar um orçamento"
                : "Solicitar um orçamento via WhatsApp"
            }
          />
        </div>
      </article>

      <article className="about2">
        <div className="container">
          <div className="left">
            <p className="text">
              <b>Faça a escolha certa</b>
            </p>

            <h2 className="title">Por que escolher o Clube de Cãopo?</h2>

            <div className="mobile">
              <Swiper
                className="carousel"
                spaceBetween={5}
                slidesPerView={
                  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                    navigator.userAgent
                  )
                    ? 1
                    : 1
                }
                navigation
                loop
                pagination={{ clickable: true }}
                autoplay={{
                  delay: 2000,
                  disableOnInteraction: false,
                }}
              >
                {data2.map(({ id, image }) => {
                  return (
                    <SwiperSlide key={id} className="img">
                      <figure>
                        <img src={image} alt={`Exemplo ${id} de Foto`} />
                      </figure>
                    </SwiperSlide>
                  );
                })}
              </Swiper>

              <figure className="stars">
                <img src={about2_stars} alt="5 Estrelas" />
              </figure>

              <p className="text">
                Atendemos a diversas raças, como: Golden, Spitz, Pitbull,
                Vira-lata e muitos outros. Temos câmeras de segurança em todos
                os cantos. Ambiente natural e amplo para a livre diversão do seu
                doguinho. Contamos também com monitores treinados para criar
                atividades, socialização, enriquecimento ambiental e muito mais
                para garantir a segurança e o bem-estar do seu Pet!
              </p>
            </div>

            <div className="gallery">
              <div className="item">
                <figure>
                  <img
                    src={about2_left_1}
                    alt="Mais de 7 anos de experiência no cuidado com Pets."
                  />
                </figure>

                <p className="item_text">
                  <b>Mais de 7 anos de experiência no cuidado com Pets.</b>
                </p>
              </div>

              <div className="item">
                <figure>
                  <img
                    src={about2_left_2}
                    alt="Monitores capacitados com treinamentos periódicos."
                  />
                </figure>

                <p className="item_text">
                  <b>Monitores capacitados com treinamentos periódicos.</b>
                </p>
              </div>

              <div className="item">
                <figure>
                  <img
                    src={about2_left_3}
                    alt="Equipe especializada no tratamento e cuidado com seu pet."
                  />
                </figure>

                <p className="item_text">
                  <b>
                    Equipe especializada no tratamento e cuidado com seu pet.
                  </b>
                </p>
              </div>
            </div>

            <CtaWpp text={"Solicitar um orçamento"} />
          </div>

          <div className="right">
            <Swiper
              className="carousel"
              spaceBetween={5}
              slidesPerView={
                /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                  navigator.userAgent
                )
                  ? 1
                  : 1
              }
              navigation
              loop
              pagination={{ clickable: true }}
              autoplay={{
                delay: 2000,
                disableOnInteraction: false,
              }}
            >
              {data2.map(({ id, image }) => {
                return (
                  <SwiperSlide key={id} className="img">
                    <figure>
                      <img src={image} alt={`Exemplo ${id} de Foto`} />
                    </figure>
                  </SwiperSlide>
                );
              })}
            </Swiper>

            <figure className="stars">
              <img src={about2_stars} alt="5 Estrelas" />
            </figure>

            <p className="text">
              Atendemos a diversas raças, como: Golden, Spitz, Pitbull,
              Vira-lata e muitos outros. Temos câmeras de segurança em todos os
              cantos. Ambiente natural e amplo para a livre diversão do seu
              doguinho. Contamos também com monitores treinados para criar
              atividades, socialização, enriquecimento ambiental e muito mais
              para garantir a segurança e o bem-estar do seu Pet!
            </p>
          </div>
        </div>
      </article>

      <article className="about3">
        <div className="container">
          <p className="text">Veja a opinião dos nossos clientes!</p>

          <h2 className="title">
            Esses são alguns dos depoimentos dos nossos clientes amigos:
          </h2>

          <Swiper
            className="carousel"
            spaceBetween={20}
            slidesPerView={
              /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                navigator.userAgent
              )
                ? 1
                : 3
            }
            loop
            pagination={{ clickable: true }}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
          >
            {data3.map(({ id, image }) => {
              return (
                <SwiperSlide key={id} className="img">
                  <figure>
                    <img src={image} alt={`Exemplo ${id} de Foto`} />
                  </figure>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </article>

      <article className="about4">
        <div className="container">
          <div className="left">
            <h2 className="title">Saiba onde nos encontrar:</h2>

            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3652.359574584691!2d-46.720704224664544!3d-23.73455367868115!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce4efa5623fd95%3A0x6778ae6f488f67dc!2sAv.%20Orfeo%20Paravente%2C%20333%20-%20Jardim%20Kioto%2C%20S%C3%A3o%20Paulo%20-%20SP%2C%2004832-090!5e0!3m2!1spt-BR!2sbr!4v1710973808745!5m2!1spt-BR!2sbr"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              title="map"
              className="map"
            ></iframe>
          </div>

          <div className="right">
            <div className="item">
              <figure>
                <img src={about4_1} alt="Horário de atendimento:" />
              </figure>

              <div className="bottom">
                <p className="item_title">Horário de atendimento:</p>

                <p className="item_text">
                  <span>Seg à Sex: </span> 07h às 20:00h |{" "}
                  <span>Sábado:  </span>
                  09h às 18:00h
                </p>
              </div>
            </div>

            <div className="item">
              <figure>
                <img src={about4_2} alt="Contatos:" />
              </figure>

              <div className="bottom">
                <p className="item_title">Contatos:</p>

                <p className="item_text">
                  <a href="tel:919012500">
                    <span>Telefone: </span> (11) 91901-2500 |{" "}
                  </a>

                  <a
                    href="http://wa.me/+5511919012500?text=Quero%20conhecer%20o%20clube%20caopo"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span> WhatsApp: </span> (11) 91901-2500
                  </a>
                </p>
              </div>
            </div>

            <div className="item">
              <figure>
                <img src={about4_3} alt="Endereço:" />
              </figure>

              <div className="bottom">
                <p className="item_title">Endereço:</p>

                <p className="item_text">
                  <a
                    href="https://maps.app.goo.gl/u7NsCw1QDBSBz2V16"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Rua Orfeu Paravente, 333 - São Paulo - SP Cep: 04832-099
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </article>
    </section>
  );
};

export default About;
