import React, { useRef } from "react";
import "./styles.css";

import { useNavigate } from "react-router-dom";

import header1 from "../../assets/header1.png";

const Form = () => {
  const formRef = useRef(null);
  const navigate = useNavigate();

  const scriptURL =
    "https://script.google.com/macros/s/AKfycbw4akUo_74iRw_qRhHcczF2TTIBi8Q4lUJF9F7J_RX5LGeSZ120AVrkLn1zGCz7hIf-/exec";

  const phoneMask = (e) => {
    if (e.target.value.length < 10) {
      return alert("Número inválido");
    } else if (e.target.value.length === 10) {
      const x = e.target.value
        .replace(/\D/g, "")
        .match(/(\d{2})(\d{4})(\d{4})/);
      e.target.value = "(" + x[1] + ") " + x[2] + "-" + x[3];
    } else {
      const x = e.target.value
        .replace(/\D/g, "")
        .match(/(\d{2})(\d{5})(\d{4})/);
      e.target.value = "(" + x[1] + ") " + x[2] + "-" + x[3];
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    document.getElementById("submit").disabled = true;
    document.getElementById("submit").style.cursor = "default";
    document.getElementById("submit").style.opacity = 0.5;

    fetch(scriptURL, {
      method: "POST",
      body: new FormData(formRef.current),
    })
      .then(() => {
        document.getElementById("form").reset();
        navigate("/sucesso");
      })
      .catch((err) => console.log(err));
  };

  return (
    <form ref={formRef} name="google-sheet" onSubmit={handleSubmit} id="form">
      <h2 className="title">Agendar Hotel</h2>
      <h3 className="form-subtitle">
        Preencha o formulário, entraremos em contato:
      </h3>

      <div className="form-div1">
        <label htmlFor="name">
          Nome: <span>*</span>
        </label>
        <input type="text" id="name" name="Nome" required />

        <label htmlFor="cel">
          (DDD) + Whatsapp: <span>*</span>
        </label>
        <input
          type="tel"
          id="cel"
          name="Whatsapp"
          onBlur={phoneMask}
          minLength="11"
          maxLength="12"
          required
        />

        <label htmlFor="pets">
          Quantos Pets Tem? <span>*</span>
        </label>
        <input type="number" id="pets" name="Quantos Pets Tem?" required />

        <label htmlFor="servico">
          Serviço Desejado: <span>*</span>
        </label>
        <select name="Serviço Desejado">
          <option value=""></option>
          <option value="Hotel Resort">Hotel Resort</option>
          <option value="Creche">Creche</option>
          <option value="Banho & Tosa">Banho & Tosa</option>
        </select>
      </div>

      <div className="form-div2">
        <div className="checkbox">
          <input
            type="checkbox"
            id="check"
            name="Contato Comercial"
            value="Quero"
            defaultChecked
          />
          <label htmlFor="check">
            Aceito receber contato comercial (Sem Spam)
          </label>{" "}
          <br />
          <button type="submit" id="submit">
            <p>Enviar</p>

            <figure>
              <img src={header1} alt="Seta para a direita" />
            </figure>
          </button>
        </div>
      </div>
    </form>
  );
};

export default Form;
